import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// components
import Layout from '../components/Layout';
import Partners from '../components/Pages/Partners';

// hooks
import useTranslated from '../hooks/useTranslated';

const PartnersComponent = ({ data, ...rest }) => {
    const { translatedData } = useTranslated(data, rest.pageContext);

    return (
        <Layout
            path={rest.location.pathname}
            lng={rest.pageContext.langKey}
            hasContainer={false}
            showOurCustomers={false}
            showWantToKnowMore={false}
            metatags={translatedData.strapiPartners.metatags}
            href="/partners/"
        >
            <Partners data={translatedData.strapiPartners} lng={rest.pageContext.langKey} />
        </Layout>
    );
};

PartnersComponent.propTypes = {
    data: PropTypes.shape({
        strapiPartners: PropTypes.shape({}).isRequired,
    }).isRequired,
};

export default PartnersComponent;

export const query = graphql`
    query PartnersEn {
        strapiPartners {
            metatags {
                description_de
                description_en
                title_de
                title_en
            }
            background {
                publicURL
            }
            button {
                label_en
                label_de
                href
            }
            description_en
            description_de
            feature {
                feature {
                    id
                    icon {
                        publicURL
                    }
                    description_de
                    description_en
                }
                title_de
                title_en
            }
            form {
                BusinessEmail {
                    label_en
                    label_de
                    placeholder_en
                    placeholder_de
                }
                LastName {
                    label_en
                    label_de
                    placeholder_en
                    placeholder_de
                }
                companyName {
                    label_en
                    label_de
                    placeholder_en
                    placeholder_de
                }
                country {
                    label_en
                    label_de
                    placeholder_en
                    placeholder_de
                    values {
                        value
                        label_en
                        label_de
                    }
                }
                firstName {
                    label_en
                    label_de
                    placeholder_en
                    placeholder_de
                }
                interest {
                    label_en
                    label_de
                    placeholder_en
                    placeholder_de
                }
                phoneNumber {
                    label_en
                    label_de
                    placeholder_en
                    placeholder_de
                }
                submitButton {
                    label_en
                    label_de
                }
                success_message_en
                success_message_de
                error_message_en
                error_message_de
            }
            text_en
            text_de
            title_en
            title_de
        }
    }
`;
