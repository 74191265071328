export function getText(values) {
    return `
*New partner request:*

First name: *${values.firstName}*
Last name: *${values.lastName}*
Business email: *${values.businessEmail}*
Company name: *${values.companyName}*
Phone number: *${values.phoneNumber}*
Country: *${values.country}*
${values.interest.trim() ? `Business: *${values.interest}*` : ''}
    `;
}
