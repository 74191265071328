import styled from 'styled-components';

export const Background = styled.div`
    background: url(${props => props.src});
    padding: 200px 0;
    background-size: cover;

    margin-bottom: 30px;

    @media all and (max-width: 768px) {
        margin-bottom: 28px;
        padding: 3em 0;

        p {
            margin-bottom: 24px;
        }
    }
`;

export const ContentContainer = styled.div`
    margin: 80px 0;
`;

export const FormContainer = styled.div`
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 24px 16px;
`;

export const RichText = styled.div`
    p {
        margin-bottom: 32px;
        color: rgba(0, 0, 0, 0.64);
        line-height: 32px;

        strong {
            color: inherit;
        }
        @media all and (max-width: 768px) {
            font-size: 0.875rem;
            line-height: 24px;
        }
    }
`;

export const Anchor = styled.div`
    height: 60px;
`;
