import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-simple-flex-grid';
import marked from 'marked';

import Message from '../../global/Message';
import { Background, ContentContainer, FormContainer, RichText, Anchor } from './styled';
import FormInput from '../../global/FormInput';
import Button from '../../global/Button';
import ButtonLink from '../../global/ButtonLink';
import Container from '../../global/Container';
import TextArea from '../../global/TextArea';
import { Heading1, Paragraph } from '../../global/Typography';
import Feature from '../../global/Feature';

import { validatePartnersForm } from '../../../utils/validators';
import { postSlackMessage } from '../../../utils/slack';
import { sleep } from '../../../utils/helpers';
import { getText } from './helpers';

const initialValues = {
    firstName: '',
    lastName: '',
    businessEmail: '',
    companyName: '',
    phoneNumber: '',
    country: '',
    interest: '',
};

const Partners = ({ data, lng }) => {
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState(initialValues);

    const [successFormSubmission, setSuccessFormSubmission] = useState(false);
    const [errorFormSubmission, setErrorFormSubmission] = useState(false);

    function handleOnChange(name, value) {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    }

    async function handlePartnersFormSubmit(event) {
        event.preventDefault();
        const { errors: foundErrors, isValid } = validatePartnersForm(values);

        setErrors(foundErrors);

        if (isValid) {
            setIsLoading(true);
            await sleep(500); // await a bit so button has a chance to show a spinner for better UX

            const { error } = await postSlackMessage(getText(values));

            if (!error) {
                setIsLoading(false);
                setValues(initialValues);
                setSuccessFormSubmission(true);
            } else {
                setIsLoading(false);
                setErrorFormSubmission(true);
            }
        }
    }

    return (
        <>
            <Background src={data.background.publicURL}>
                <Container narrow padding="0 15% 0 20px">
                    <Heading1 white marginBottom="24px">
                        {data.title}
                    </Heading1>
                    <Paragraph white marginBottom="24px">
                        {data.description}
                    </Paragraph>
                    <ButtonLink
                        label={data.button.label}
                        href={data.button.href}
                        lng={lng}
                        primary={1}
                    />
                </Container>
            </Background>
            <Container narrow>
                <Feature data={data.feature} hasBoxShadow={false} />
            </Container>
            <Container narrow>
                <ContentContainer>
                    <Row gutter={24}>
                        <Col xs={12}>
                            <Anchor id="form-anchor" />
                        </Col>
                        <Col xs={12} md={6}>
                            {data.text ? (
                                <RichText
                                    dangerouslySetInnerHTML={{
                                        __html: marked(data.text),
                                    }}
                                />
                            ) : null}
                        </Col>

                        <Col xs={12} md={6}>
                            <FormContainer>
                                <form onSubmit={handlePartnersFormSubmit}>
                                    <Row gutter={24}>
                                        <Col xs={12} sm={6}>
                                            <FormInput
                                                label={data.form.firstName.label}
                                                id="first-name"
                                                name="firstName"
                                                placeholder={data.form.firstName.placeholder}
                                                onChange={handleOnChange}
                                                value={values.firstName}
                                                error={errors.firstName}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <FormInput
                                                label={data.form.LastName.label}
                                                id="last-name"
                                                name="lastName"
                                                placeholder={data.form.LastName.placeholder}
                                                onChange={handleOnChange}
                                                error={errors.lastName}
                                                value={values.lastName}
                                            />
                                        </Col>
                                    </Row>
                                    <FormInput
                                        label={data.form.BusinessEmail.label}
                                        id="business-email"
                                        name="businessEmail"
                                        placeholder={data.form.BusinessEmail.placeholder}
                                        onChange={handleOnChange}
                                        error={errors.businessEmail}
                                        value={values.businessEmail}
                                    />
                                    <FormInput
                                        label={data.form.companyName.label}
                                        id="company-name"
                                        name="companyName"
                                        placeholder={data.form.companyName.placeholder}
                                        error={errors.companyName}
                                        onChange={handleOnChange}
                                        value={values.companyName}
                                    />
                                    <FormInput
                                        label={data.form.phoneNumber.label}
                                        id="phone-number"
                                        name="phoneNumber"
                                        placeholder={data.form.phoneNumber.placeholder}
                                        onChange={handleOnChange}
                                        error={errors.phoneNumber}
                                        value={values.phoneNumber}
                                        type="number"
                                    />
                                    <FormInput
                                        label={data.form.country.label}
                                        placeholder={data.form.country.placeholder}
                                        value={values.country}
                                        name="country"
                                        id="country"
                                        options={data.form.country.values}
                                        error={errors.country}
                                        onChange={handleOnChange}
                                        type="text"
                                    />
                                    <TextArea
                                        label={data.form.interest.label}
                                        id="interest"
                                        name="interest"
                                        placeholder={data.form.interest.placeholder}
                                        onChange={handleOnChange}
                                        value={values.interest}
                                    />
                                    {/* If form submission is success do not show button anymore but the message */}
                                    {successFormSubmission ? (
                                        <Message type="success" text={data.form.success_message} />
                                    ) : (
                                        <Button
                                            width="100%"
                                            type="submit"
                                            isLoading={isLoading}
                                            label={data.form.submitButton.label}
                                        />
                                    )}

                                    {/* Show error message if submission failed */}
                                    {errorFormSubmission && (
                                        <Message type="error" text={data.form.error_message} />
                                    )}

                                    {/* Linkedin tracking after successful form submit */}
                                    {successFormSubmission && (
                                        <img
                                            height="1"
                                            width="1"
                                            style={{ display: 'none' }}
                                            alt="linkedin pixel"
                                            src="https://px.ads.linkedin.com/collect/?pid=3280945&conversionId=4192497&fmt=gif"
                                        />
                                    )}
                                </form>
                            </FormContainer>
                        </Col>
                    </Row>
                </ContentContainer>
            </Container>
        </>
    );
};

Partners.propTypes = {
    data: PropTypes.shape({
        form: PropTypes.shape({
            success_message: PropTypes.string,
            error_message: PropTypes.string,
            submitButton: PropTypes.shape({
                label: PropTypes.string.isRequired,
            }).isRequired,
            interest: PropTypes.shape({
                placeholder: PropTypes.string,
                label: PropTypes.string.isRequired,
            }).isRequired,
            country: PropTypes.shape({
                placeholder: PropTypes.string,
                label: PropTypes.string.isRequired,
                values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
            }).isRequired,
            companyName: PropTypes.shape({
                placeholder: PropTypes.string,
                label: PropTypes.string.isRequired,
            }).isRequired,
            phoneNumber: PropTypes.shape({
                placeholder: PropTypes.string,
                label: PropTypes.string.isRequired,
            }).isRequired,
            BusinessEmail: PropTypes.shape({
                placeholder: PropTypes.string,
                label: PropTypes.string.isRequired,
            }).isRequired,
            LastName: PropTypes.shape({
                placeholder: PropTypes.string,
                label: PropTypes.string.isRequired,
            }).isRequired,
            firstName: PropTypes.shape({
                placeholder: PropTypes.string,
                label: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        text: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        background: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        button: PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        }).isRequired,
        feature: PropTypes.shape({}).isRequired,
    }).isRequired,
    lng: PropTypes.string.isRequired,
};

export default Partners;
